import { FC, useEffect, useState } from "react";
import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  JustifyContent,
  PlainLink,
  Space,
  Text,
  Visibility,
  useTheme,
} from "@gocardless/flux-react";
// eslint-disable-next-line import/no-unresolved
import ReferButtonIconHeart from "src/assets/svg/refer-button-icon-heart.svg";
// eslint-disable-next-line import/no-unresolved
import DoubleRewardsReferButtonIconHeart from "src/assets/svg/double-rewards-refer-button-icon-heart.svg";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useReferralAmount } from "src/components/routes/Refer/hooks/useReferralAmount";
import { OptimizelyDecideOption } from "@optimizely/react-sdk";
import { usePrimaryCreditor } from "src/queries/creditor";
import { Trans } from "@lingui/macro";
import { ReferralsSource } from "src/common/trackingEventAttributes";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useLocalStorage } from "react-use";

import { LinkBuilder, Route } from "../routing";
import { OptimizelyFlag } from "../third-parties/Optimizely/constants";
import { useOptimizelyVariation } from "../third-parties/Optimizely/useOptimizelyVariation";

import { ReferPopup } from "./ReferPopup";
import { DoubleRewardsReferPopup } from "./DoubleRewardsReferPopup";

const REFER_POPUP_KEY = "REFERRAL_POPUP_DISMISSED";

const ReferButton: FC = () => {
  const { theme } = useTheme();
  const { sendEvent } = useSegment();
  const referralAmount = useReferralAmount();
  const creditor = usePrimaryCreditor();
  const { data: userData } = useUserShowSelf();
  const [referPopupDismissed] = useLocalStorage(REFER_POPUP_KEY, String(false));
  const [showPopup, setShowPopup] = useState(false);

  const referPopupShowed =
    userData?.users?.dashboard_preferences?.referral_popup_dismissed ||
    referPopupDismissed === "true";

  // disable optimizely decision event as this experiment is part of GLOBAL_EXPERIMENT_DECISIONS
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.REFERRAL_SCHEME_V2,
    options: {
      decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
    },
  });

  const { isVariationOn: showReferralPromoPopup } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REFERRAL_PROMO_POPUP,
  });

  const { isVariationOn: isDoubleRewardsPromotionEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_DOUBLE_REWARDS_PROMOTION,
    });

  useEffect(() => {
    if (showReferralPromoPopup && creditor?.activated && !referPopupShowed) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [showReferralPromoPopup, creditor?.activated, referPopupShowed]);

  return isVariationOn ? (
    <Visibility visible={["none", null, null, "block"]}>
      <Box
        gutterH={1}
        gutterV={0.5}
        bg={
          isDoubleRewardsPromotionEnabled
            ? Color.Ultraviolet_50
            : showPopup
              ? Color.White
              : Color.Transparent
        }
        borderRadius={3}
        css={
          showPopup && {
            zIndex: 3,
            position: "relative",
            backgroundColor: isDoubleRewardsPromotionEnabled
              ? theme.color(Color.Ultraviolet_50)
              : "white",
            boxShadow: "0px 0px 8px 0px #FFF",
          }
        }
      >
        <LinkBuilder
          route={Route.Refer}
          onClick={() => {
            sendEvent(TrackingEvent.DASHBOARD_REFER_CTA_CLICKED, {
              source: ReferralsSource.REFER_BUTTON,
            });
          }}
        >
          {(result) => (
            <PlainLink {...result}>
              <Box
                layout="flex"
                flexDirection="row"
                alignItems={AlignItems.Center}
              >
                <Box
                  layout="flex"
                  flexDirection="row"
                  justifyContent={JustifyContent.Center}
                  alignItems={AlignItems.Center}
                  width="24px"
                  height="24px"
                  bg={
                    isDoubleRewardsPromotionEnabled
                      ? Color.White
                      : Color.Sunrise_700
                  }
                  borderRadius={2}
                >
                  <img
                    src={
                      isDoubleRewardsPromotionEnabled
                        ? DoubleRewardsReferButtonIconHeart
                        : ReferButtonIconHeart
                    }
                    alt=""
                    width={16}
                    height={16}
                  />
                </Box>
                <Space layout="inline" h={0.5} />
                <Text weight={FontWeight.SemiBold}>
                  {isDoubleRewardsPromotionEnabled ? (
                    <Trans id="double-rewards-referral.button-label">
                      Refer and get {referralAmount.incentive}
                    </Trans>
                  ) : (
                    <Trans id="refer.button-label">
                      Refer & get {referralAmount.incentive}
                    </Trans>
                  )}
                </Text>
              </Box>
            </PlainLink>
          )}
        </LinkBuilder>
      </Box>

      {isDoubleRewardsPromotionEnabled ? (
        <DoubleRewardsReferPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      ) : (
        <ReferPopup showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
    </Visibility>
  ) : (
    <></>
  );
};

export default ReferButton;
