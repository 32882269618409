import { useUserShowSelf } from "@gocardless/api/staff/user";
import { Link, LinkProps } from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { ZendeskUrl } from "src/utils/zendesk";

export interface ZendeskLink extends Omit<LinkProps, "href"> {
  formId: number;
  params?: URLSearchParams;
}

export const ZendeskLink: React.FC<ZendeskLink> = ({
  children,
  formId,
  params = new URLSearchParams(),
  ...props
}) => {
  const { data } = useUserShowSelf();
  const { sendEvent } = useSegment();
  const { pathname } = useRouter();

  const { href } = new ZendeskUrl(String(formId))
    .withSearchParams(params)
    .withEmail(data?.users?.email);

  return (
    <Link
      target="_blank"
      onClick={(event) => {
        const { trackingLabel: label, trackingDetail: detail } =
          event.currentTarget.dataset;

        sendEvent(TrackingEvent.SUPPORT_ZENDESK_LINK_CLICKED, {
          zdFormId: formId,
          pathname,
          label,
          detail,
        });
      }}
      {...{ href, ...props }}
    >
      {children}
    </Link>
  );
};
