import {
  AlignItems,
  Box,
  Button,
  ButtonGroup,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorScheme,
  Glyph,
  IconButton,
  P,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import ReferIllustration from "src/assets/png/refer-popuptip-illustration@2x.png";
import Router from "next/router";
import { Route, getRoute } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { Trans } from "@lingui/macro";
import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import { ReferralsSource } from "src/common/trackingEventAttributes";

import { useReferralAmount } from "../routes/Refer/hooks/useReferralAmount";
import { useSegment } from "../third-parties/Segment/useSegment";

interface ReferPopupProps {
  setShowPopup: (e: boolean) => void;
  showPopup: boolean;
}

export const ReferPopup: React.FC<ReferPopupProps> = ({
  setShowPopup,
  showPopup,
}) => {
  const { theme } = useTheme();
  const referralAmount = useReferralAmount();
  const { sendEventPromise, sendEvent } = useSegment();
  const { data: userData, mutate } = useUserShowSelf();
  const [updateUser] = useUserUpdateSelf({ onSuccess: () => mutate() });

  const setReferPopupShowed = () => {
    updateUser({
      dashboard_preferences: {
        ...userData?.users?.dashboard_preferences,
        referral_popup_dismissed: true,
      },
    });
  };

  const closePopup = (target: string) => {
    sendEvent(TrackingEvent.DASHBOARD_REFER_POPUP_DISMISSED, {
      target,
      source: ReferralsSource.POPUP,
    });
    setShowPopup(false);
    setReferPopupShowed();
  };

  return showPopup ? (
    <Box>
      <Box
        css={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          paddingTop: "50px",
          paddingBottom: "50px",
          zIndex: 2,
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      />
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "calc(100% + 8px)",
          right: 290,
          width: "384px",
          overflow: " visible !important",
          zIndex: 2,
          borderRadius: theme.spacing(1),
          borderTop: `1px solid ${theme.color(Color.White)}`,
          background: theme.color(Color.White),
          animationName: "refer-popup-animation",
          animationDuration: "0.2s",
          animationDelay: "0.3s",
          animationTimingFunction: " ease-in-out",
          animationFillMode: "both",
          ":before": {
            content: '""',
            position: "absolute",
            top: "-8px",
            left: "calc(50% + 138px)",
            width: 0,
            height: 0,
            borderLeft: `${theme.spacing(0.5)} solid transparent`,
            borderRight: `${theme.spacing(0.5)} solid transparent`,
            borderBottom: `${theme.spacing(0.5)} solid ${theme.color(
              Color.White
            )}`,
          },
        }}
      >
        <Box alignSelf={AlignItems.FlexEnd} gutterH={0.5} gutterV={0.5}>
          <IconButton
            icon={Glyph.Close}
            label="close button"
            variant={ButtonVariant.TextOnLight}
            size={{ gutters: ButtonGutter.Sm, base: ButtonSize.Sm }}
            onClick={() => closePopup("close")}
          />
        </Box>
        <Box gutterH={1.5} spaceBelow={1.5}>
          <Box>
            <Tag
              variant={TagVariant.Solid}
              color={TagColor.Primary}
              colorScheme={ColorScheme.OnDark}
              size={TagSize.Lg}
            >
              <Trans id="referral-promo-popup.limited-time-offer">
                Limited time offer
              </Trans>
            </Tag>
          </Box>
          <P preset={TypePreset.Heading_04} spaceAbove={1}>
            <Trans id="referral-promo-popup.share-gocardless">
              Share GoCardless with another business & get{" "}
              {referralAmount.incentive} each
            </Trans>
          </P>
          <Box spaceAbove={1}>
            <img width="100%" src={ReferIllustration} alt="" />
          </Box>

          <Box spaceAbove={1}>
            <ButtonGroup>
              <Button
                variant={ButtonVariant.PrimaryOnLight}
                size={ButtonSize.Sm}
                onClick={async () => {
                  await sendEventPromise(
                    TrackingEvent.DASHBOARD_REFER_CTA_CLICKED,
                    { source: ReferralsSource.POPUP }
                  );
                  closePopup("close");
                  Router.push(getRoute(Route.Refer));
                }}
              >
                <Trans id="referral-promo-popup.refer-and-earn">
                  Refer & earn
                </Trans>
              </Button>
              <Button
                variant={ButtonVariant.SecondaryOnLight}
                size={ButtonSize.Sm}
                onClick={() => closePopup("got_it")}
              >
                <Trans id="referral-promo-popup.got-it">Got it</Trans>
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};
