import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  HoverEffect,
  PlainButton,
  Separator,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";

const ExtendDiscountButton: React.FC<{ onClickExtend: () => void }> = ({
  onClickExtend,
}) => {
  const { remainingDays, canExtendDiscount } = useActivationDiscount();

  return remainingDays ? (
    <Box
      gutterH={0.75}
      gutterV={0.5}
      bg={Color.Ultraviolet_50}
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
      css={{ borderRadius: "12px" }}
    >
      <Box
        bg={ColorPreset.BackgroundDark_01}
        gutterH={0.5}
        spaceAfter={0.5}
        css={{ borderRadius: "6px" }}
      >
        <Text
          color={ColorPreset.BackgroundLight_01}
          weight={FontWeight.SemiBold}
          size={4}
        >
          {remainingDays}
        </Text>
      </Box>
      <Text
        color={ColorPreset.BackgroundDark_01}
        weight={FontWeight.SemiBold}
        size={2}
      >
        <Trans id="extend-discount.days-remaining">
          days left with no fees
        </Trans>
      </Text>
      {canExtendDiscount && (
        <>
          <Box gutterH={0.5}>
            <Separator
              direction="inline-vertical"
              color={Color.Greystone_1400_A16}
            />
          </Box>
          <PlainButton
            onClick={onClickExtend}
            textDecoration="underline"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.SemiBold}>
              <Trans id="extend-discount.extend-label">Extend</Trans>
            </Text>
          </PlainButton>
        </>
      )}
    </Box>
  ) : null;
};

export default ExtendDiscountButton;
