import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { AccessTokenCreateRequestBodyScope } from "@gocardless/api/dashboard/types";
import { useEffect, useState } from "react";

export const useUserPermissions = () => {
  const [loading, setLoading] = useState(true);
  const { data, error } = useUserShowSelf();

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  return {
    ...getScope(data?.users?.scope),
    error,
    loading,
  };
};

const getScope = (scope?: AccessTokenCreateRequestBodyScope) => {
  switch (scope) {
    case AccessTokenCreateRequestBodyScope.Admin:
      return { isAdmin: true };
    case AccessTokenCreateRequestBodyScope.ReadWrite:
      return { isReadWrite: true };
    case AccessTokenCreateRequestBodyScope.ReadOnly:
      return { isRead: true };
    default:
      return { isRead: true };
  }
};
