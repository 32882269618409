import {
  Box,
  Color,
  ColorPreset,
  FontWeight,
  HoverEffect,
  JustifyContent,
  PlainButton,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";

const ExtendDiscountBanner: React.FC<{ onClickExtend: () => void }> = ({
  onClickExtend,
}) => {
  const { remainingDays, canExtendDiscount } = useActivationDiscount();

  return remainingDays ? (
    <Box
      gutterH={1.5}
      gutterV={0.75}
      bg={Color.Ultraviolet_50}
      layout="flex"
      flexDirection="row"
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Text color={ColorPreset.BackgroundDark_01} weight={FontWeight.SemiBold}>
        {remainingDays}{" "}
        <Trans id="extend-discount.days-remaining">
          days left with no fees
        </Trans>
      </Text>
      {canExtendDiscount && (
        <PlainButton
          onClick={onClickExtend}
          textDecoration="underline"
          effect={HoverEffect.TextDecoration}
        >
          <Text weight={FontWeight.SemiBold}>
            <Trans id="extend-discount.extend-label">Extend</Trans>
          </Text>
        </PlainButton>
      )}
    </Box>
  ) : null;
};

export default ExtendDiscountBanner;
