import { ColorPreset, Separator, SeparatorProps } from "@gocardless/flux-react";

import { SeparatorType } from "./types";

interface SectionSeparatorProps {
  type: SeparatorType;
}

const separatorTypes: Record<SeparatorType, SeparatorProps> = {
  [SeparatorType.SETTINGS]: {
    length: ["100%", null, "570px"],
    spacing: [[3, 0, 3]],
  },
  [SeparatorType.PREVIEW]: {
    length: "100%",
    spacing: [[0, 0]],
  },
  [SeparatorType.DEFAULT]: {
    length: "100%",
    spacing: [[0, 0, 2, 0]],
  },
};

export const SectionSeparator: React.FC<SectionSeparatorProps> = (props) => {
  const { length, spacing } = separatorTypes[props.type];

  return (
    <Separator
      direction="block-horizontal"
      color={ColorPreset.BorderOnLight_04}
      length={length}
      spacing={spacing}
    />
  );
};

export default SectionSeparator;
