import { ReactElement, ReactNode } from "react";
import { NextPage } from "next";
import {
  BoxProps,
  ResponsiveValue,
  ColumnSize,
  JustifyContent,
  Glyph,
} from "@gocardless/flux-react";

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export interface ActionTitleBarProps
  extends Pick<BoxProps, "height" | "elevation" | "gutterH"> {
  centerContent?: ReactNode;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  centerAreaSize?: ResponsiveValue<"auto" | ColumnSize>;
  leftAreaSize?: ResponsiveValue<"auto" | ColumnSize>;
  rightAreaSize?: ResponsiveValue<"auto" | ColumnSize>;
  justifyCenterContent?: ResponsiveValue<JustifyContent>;
  justifyLeftContent?: ResponsiveValue<JustifyContent>;
  justifyRightContent?: ResponsiveValue<JustifyContent>;
  bottomSeparator?: ReactNode;
}

export interface BackNavigationProps {
  title: string;
  onClick: () => void;
  icon?: Glyph;
}

export interface NavigationTitleBarProps extends ActionTitleBarProps {
  title?: ReactNode;
  backNavigationHref: string;
  backNavigationTitle?: string;
  backNavigationOnClick?: () => void;
  icon?: Glyph;
}

export enum SeparatorType {
  SETTINGS = "settings",
  PREVIEW = "preview",
  DEFAULT = "default",
}
